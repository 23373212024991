// src/components/Loading.js
import React from 'react';

const Loading = ({ message }) => {
    return (
        <div className="loading-container">
            <div className="spinner"></div>
            <h1>Aguarde: {message}</h1>
        </div>
    );
};

export default Loading;
