import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import Loading from './Loading';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    CircularProgress,
    DialogContentText,
    // Alert, // Importa Alert para exibir mensagens
} from '@mui/material';
import dayjs from 'dayjs'; // Para formatação de datas

const CheckIn = ({ hash, onBack }) => {
    // const { hash } = useParams(); // Pega o hash da URL
    const [eventData, setEventData] = useState(null); // Inicia sem dados do evento
    const [cpf, setCpf] = useState('');
    const [name, setName] = useState('');
    const [isCpfValid, setIsCpfValid] = useState(null); // Define null como padrão para indicar que não há validação ainda
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false); // Controle de modal de confirmação
    const [openWaitingDialog, setOpenWaitingDialog] = useState(false); // Controle de modal de aguardando
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false); // Controle de modal de sucesso
    const [openWarningDialog, setOpenWarningDialog] = useState(false); // Controle de modal de aviso
    const [openErrorDialog, setOpenErrorDialog] = useState(false); // Controle de modal de erro
    const [participantId, setParticipantId] = useState(null); // Armazena o ID do participante
    const [localId, setLocalId] = useState(null); // Armazena o ID do local
    const [cursoId, setCursoId] = useState(null); // Armazena o ID do curso
    const [feedbackMessage, setFeedbackMessage] = useState(''); // Armazena a mensagem de feedback
    const [registroMessage, setRegistroMessage] = useState(''); // Armazena a mensagem de feedback
    const [feedbackSeverity, setFeedbackSeverity] = useState(''); // Armazena a gravidade da mensagem

    // Definindo as rotas da API
    const API_BASE_URL = 'https://comepi-api-acl.comepilabs.com.br/api/cki';
    const apiRoutes = {
        getEventByHash: `${API_BASE_URL}/curso/consultar-hash/${hash}`,
        validateCpf: (cpf) => `${API_BASE_URL}/participante/consultar-cpf/${cpf}`,
        registerCheckIn: `${API_BASE_URL}/participante/registro`,
    };

    const resetForm = () => {
        setCpf(''); // Limpa o campo de CPF
        setIsCpfValid(null); // Reseta a validação do CPF
        setName(''); // Limpa o nome
        setParticipantId(null); // Reseta o ID do participante
    };

    // Função para buscar dados do evento pela hash
    useEffect(() => {
        const fetchEventData = async () => {
            try {
                const response = await fetch(apiRoutes.getEventByHash, {
                    method: 'GET',
                });

                if (!response.ok) {
                    throw new Error('Erro ao buscar dados do curso');
                }

                const data = await response.json();

                setEventData({
                    title: data.nome.toUpperCase(),
                    date: data.data,
                    time: data.horario,
                    location: data['Cs_Local.nome'],
                });

                setCursoId(data.id); // Salva o ID do curso
                setLocalId(data.local); // Salva o ID do local
            } catch (error) {
                console.error('Erro ao buscar dados do evento:', error);
                setFeedbackMessage("Erro ao buscar dados do evento.");
                setFeedbackSeverity('error'); // Indica um erro
            }
        };

        fetchEventData();
    }, [hash]);

    // Função de validação de CPF pela API
    const validateCpf = async (cpf) => {
        try {
            const cleanedCpf = cpf.replace(/[.-]/g, ''); // Remove . e -

            const response = await fetch(apiRoutes.validateCpf(cleanedCpf), {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Erro na consulta do CPF');
            }

            const data = await response.json();

            if (data.valid) {
                setName(data.name);
                setParticipantId(data.id); // Salva o ID do participante
                setIsCpfValid(true); // CPF válido
            } else {
                setName('');
                setParticipantId(null); // Reseta o ID do participante
                setIsCpfValid(false); // CPF inválido
            }
        } catch (error) {
            console.error("Erro ao validar CPF:", error);
            setFeedbackMessage("Erro ao validar CPF.");
            setFeedbackSeverity('error'); // Indica um erro
        }
    };

    // Função chamada a cada mudança no CPF
    const handleCpfChange = (e) => {
        const cpfValue = e.target.value;
        setCpf(cpfValue);

        // Quando CPF estiver completo, dispara validação
        if (cpfValue.replace(/[^\d]/g, '').length === 11) {
            validateCpf(cpfValue);
        } else {
            setIsCpfValid(null); // Reseta a validação enquanto o CPF ainda não está completo
        }
    };

    // Função para abrir o modal de confirmação
    const handleCheckInClick = () => {
        setOpenConfirmDialog(true);
    };

    // Função para confirmar o check-in e registrar na API
    const handleConfirmCheckIn = async () => {
        setOpenConfirmDialog(false);
        setOpenWaitingDialog(true);
        setIsSubmitting(true);

        try {
            const response = await fetch(apiRoutes.registerCheckIn, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    cursoId: cursoId,
                    participanteId: participantId,
                    lojaId: localId,
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error('Erro ao registrar o check-in');
            }

            if (data.status === false) {
                // Exibe o modal de aviso se o CPF já foi registrado
                setOpenWaitingDialog(false);
                setOpenWarningDialog(true);  // Abre o modal de aviso
                setFeedbackMessage(data.message);
                setFeedbackSeverity('warning'); // Indica um aviso
                setIsSubmitting(false);
            } else {
                // Exibe o modal de sucesso para o check-in realizado
                const checkInDate = dayjs(data.registro.check_in).format('DD/MM/YYYY HH:mm');
                setTimeout(() => {
                    setOpenWaitingDialog(false);
                    setOpenSuccessDialog(true);
                    setFeedbackMessage(data.message);
                    setRegistroMessage(`Data e hora do check-in: ${checkInDate}`);
                    setFeedbackSeverity('success'); // Indica sucesso
                    setIsSubmitting(false);
                }, 2000);
            }
        } catch (error) {
            console.error('Erro ao registrar o check-in:', error);
            setOpenWaitingDialog(false);
            setIsSubmitting(false);
            setOpenErrorDialog(true); // Abre o modal de erro
            setFeedbackMessage("Ocorreu um erro ao registrar o check-in.");
            setFeedbackSeverity('error'); // Indica um erro
        }
    };

    const handleCloseSuccessDialog = () => {
        setOpenSuccessDialog(false);
        resetForm(); // Limpa o formulário após o sucesso
    };

    const handleCloseWarningDialog = () => {
        setOpenWarningDialog(false);
        resetForm(); // Limpa o formulário após o aviso
    };

    const handleCloseErrorDialog = () => {
        setOpenErrorDialog(false);
        resetForm(); // Limpa o formulário após o erro
    };

    // Define classes CSS condicionalmente para mudar a cor do campo
    const cpfInputClass = isCpfValid === null ? '' : isCpfValid ? 'is-valid' : 'is-invalid';

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="card shadow" style={{ width: '400px', padding: '20px', borderRadius: '8px' }}>
                {eventData ? (
                    <>
                        <h3 className="text-center">{eventData.title}</h3>
                        <p className="text-center">Data: {eventData.date}</p>
                        <p className="text-center">Hora: {eventData.time}</p>
                        <p className="text-center">Local: {eventData.location}</p>

                        <div className="mb-3">
                            <label htmlFor="cpf" className="form-label">CPF:</label>
                            <InputMask
                                mask="999.999.999-99"
                                value={cpf}
                                onChange={handleCpfChange}
                                className={`form-control ${cpfInputClass}`} // Classe condicional
                                placeholder="Digite seu CPF"
                            />
                            <div className={isCpfValid === false ? "invalid-feedback" : "valid-feedback"}>
                                {isCpfValid === false ? "CPF inválido!" : "CPF válido!"}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Nome:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                value={name}
                                disabled
                                placeholder="Nome será preenchido após a validação do CPF"
                            />
                        </div>

                        <button
                            type="submit"
                            className="btn btn-primary w-100"
                            disabled={!isCpfValid || isSubmitting} // Desabilita o botão se o CPF for inválido ou estiver submetendo
                            onClick={handleCheckInClick} // Botão que abre o modal de confirmação
                        >
                            {isSubmitting ? <CircularProgress size={24} /> : 'Realizar Check-In'}
                        </button>
                        <button onClick={onBack} className="btn btn-dark mt-3">Voltar</button>
                    </>
                ) : (
                    <p>Aguarde: Carregando dados do evento...</p>
                )}
            </div>

            {/* Diálogo de confirmação */}
            <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
                <DialogTitle>Confirmar Check-In</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Você tem certeza que deseja realizar o check-in?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDialog(false)}>Cancelar</Button>
                    <Button onClick={handleConfirmCheckIn} color="primary">Confirmar</Button>
                </DialogActions>
            </Dialog>

            {/* Diálogo de aguardando */}
            <Dialog open={openWaitingDialog} onClose={() => setOpenWaitingDialog(false)}>
                <DialogTitle>Aguarde</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Registrando seu check-in...
                    </DialogContentText>
                    <CircularProgress />
                </DialogContent>
            </Dialog>

            {/* Diálogo de sucesso */}
            <Dialog open={openSuccessDialog} onClose={handleCloseSuccessDialog}>
                <DialogTitle>Sucesso!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {feedbackMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogContent>
                    <DialogContentText>
                        {registroMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSuccessDialog} color="primary">Fechar</Button>
                </DialogActions>
            </Dialog>

            {/* Diálogo de aviso */}
            <Dialog open={openWarningDialog} onClose={handleCloseWarningDialog}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {feedbackMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseWarningDialog} color="primary">Fechar</Button>
                </DialogActions>
            </Dialog>

            {/* Diálogo de erro */}
            <Dialog open={openErrorDialog} onClose={handleCloseErrorDialog}>
                <DialogTitle>Erro</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {feedbackMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseErrorDialog} color="primary">Fechar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CheckIn;