import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Loading from './Loading';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Modal, Button, Badge } from 'react-bootstrap';
import '../styles/global.css';

// Definindo as rotas da API
const API_BASE_URL = 'https://comepi-api-acl.comepilabs.com.br/api/cki';
// const API_BASE_URL = 'http://localhost:3001/api/cki';
const apiRoutes = {
    getCursoLista: `${API_BASE_URL}/curso/lista-com-participantes`,
    getCursoDetalhes: (cursoId) => `${API_BASE_URL}/curso-participante/curso/${cursoId}`,
};

const EventList = ({ onCheckIn }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [participantes, setParticipantes] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const eventsPerPage = 5;

    // Novos estados para participantes
    const [searchTermParticipantes, setSearchTermParticipantes] = useState('');
    const [currentPageParticipantes, setCurrentPageParticipantes] = useState(0);
    const participantesPerPage = 5;

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await axios.get(apiRoutes.getCursoLista);
                const formattedEvents = response.data.map(event => ({
                    ...event,
                    Cs_Categorium: { nome: event['Cs_Categorium.nome'] },
                    Cs_Local: { nome: event['Cs_Local.nome'] }
                }));
                setEvents(formattedEvents);
                setLoading(false);
            } catch (error) {
                console.error('Erro ao buscar os cursos:', error);
                setLoading(false);
            }
        };

        fetchCourses();
    }, []);

    const formatEventDateTime = (dateString, timeString) => {
        const dateTime = parseISO(`${dateString}T${timeString}`);
        const formattedDate = format(dateTime, "dd/MM/yyyy", { locale: ptBR });
        const formattedTime = format(dateTime, "HH'h'mm'm'", { locale: ptBR });
        const relativeDate = formatDistanceToNow(dateTime, { addSuffix: true, locale: ptBR });
        return `${relativeDate} (${formattedDate} às ${formattedTime})`;
    };

    const handleShowDetails = async (cursoId) => {
        setIsModalLoading(true);
        setIsModalOpen(true);

        try {
            const response = await axios.get(apiRoutes.getCursoDetalhes(cursoId));
            setSelectedEvent(response.data);
            setParticipantes(response.data || []);
            setIsModalLoading(false);
        } catch (error) {
            console.error('Erro ao buscar detalhes do curso:', error);
            setIsModalLoading(false);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedEvent(null);
        setParticipantes([]);
        setSearchTermParticipantes(''); // Limpar o termo de busca
        setCurrentPageParticipantes(0); // Resetar a página dos participantes
    };

    const filteredEvents = events.filter(event =>
        event.nome && event.nome.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Contagem total de páginas com base nos eventos filtrados
    const pageCount = Math.ceil(filteredEvents.length / eventsPerPage);
    // Exibe apenas os eventos da página atual
    const displayedEvents = filteredEvents.slice(currentPage * eventsPerPage, (currentPage + 1) * eventsPerPage);

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Filtrando os participantes com base no termo de busca
    const filteredParticipantes = participantes.filter(participante =>
        participante['Participante.nome']?.toLowerCase().includes(searchTermParticipantes.toLowerCase())
    );

    // Contagem total de páginas com base nos participantes filtrados
    const pageCountParticipantes = Math.ceil(filteredParticipantes.length / participantesPerPage);
    // Exibe apenas os participantes da página atual
    const displayedParticipantes = filteredParticipantes.slice(currentPageParticipantes * participantesPerPage, (currentPageParticipantes + 1) * participantesPerPage);

    const handlePageClickParticipantes = ({ selected }) => {
        setCurrentPageParticipantes(selected);
    };

    if (loading) {
        return <Loading message="Carregando a lista de cursos" />;
    }

    return (
        <div className="container mt-4">
            <input
                type="text"
                placeholder="Buscar por título"
                className="form-control mb-3"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            <div className="row">
                {displayedEvents.map(event => (
                    <div key={event.id} className="col-md-4 mb-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">{event.nome.toUpperCase() || 'Nome indisponível'}</h5>

                                {/* Badges para total de participantes e check-ins */}
                                <div className="row mt-3 mb-3">
                                    <div className="col-6 text-center">
                                        <span className="badge bg-primary">{event.totalParticipantes} participantes</span>
                                    </div>
                                    <div className="col-6 text-center">
                                        <span className={`badge ${event.totalCheckIn === event.totalParticipantes ? 'bg-success' : 'bg-warning'}`}>
                                            {event.totalCheckIn} de {event.totalParticipantes} check-in{event.totalCheckIn !== 1 ? 's' : ''} realizados
                                        </span>
                                    </div>
                                </div>

                                <p className="card-text">{event.Cs_Categorium?.nome || 'Categoria indisponível'}</p>
                                <p className="text-muted">
                                    {event.data && event.horario ? formatEventDateTime(event.data, event.horario) : 'Data e horário não definidos'}
                                </p>

                                <p className="text-muted">{event.Cs_Local?.nome || 'Local não definido'}</p>

                                {/* Botões para ações */}
                                <div className="row mt-2">
                                    <div className="col-6 text-center">
                                        <button className="btn btn-success" onClick={() => handleShowDetails(event.id)}>
                                            Ver Detalhes
                                        </button>
                                    </div>
                                    <div className="col-6 text-center">
                                        <button className="btn btn-info" onClick={() => onCheckIn(event.hash_link)}>Check-in</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>



            <ReactPaginate
                previousLabel={"Anterior"}
                nextLabel={"Próximo"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
            />

            {/* Modal para mostrar os detalhes do curso */}
            <Modal show={isModalOpen} onHide={handleCloseModal} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Detalhes do Curso</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isModalLoading ? (
                        <Loading message="Carregando detalhes do curso..." />
                    ) : selectedEvent ? (
                        <>
                            <h6>Participantes:</h6>
                            <input
                                type="text"
                                placeholder="Buscar por participante"
                                className="form-control mb-3"
                                value={searchTermParticipantes}
                                onChange={(e) => setSearchTermParticipantes(e.target.value)}
                            />
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>CPF</th>
                                            <th>E-mail</th>
                                            <th>Check-in</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayedParticipantes.length > 0 ? (
                                            displayedParticipantes.map(participante => (
                                                <tr key={participante.id}>
                                                    <td>{participante['Participante.nome'] || 'Nome indisponível'}</td>
                                                    <td>{participante['Participante.cpf'] || 'CPF indisponível'}</td>
                                                    <td>{participante['Participante.email'] || 'E-mail indisponível'}</td>
                                                    <td>
                                                        {participante.check_in === null ? (
                                                            <Badge pill bg="danger">Não Realizado</Badge>
                                                        ) : (
                                                            <Badge pill bg="success">Realizado</Badge>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4">Nenhum participante registrado.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <ReactPaginate
                                previousLabel={"Anterior"}
                                nextLabel={"Próximo"}
                                breakLabel={"..."}
                                pageCount={pageCountParticipantes}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClickParticipantes}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </>
                    ) : (
                        <p>Nenhum detalhe disponível.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EventList;