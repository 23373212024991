import React, { useState } from 'react';
import EventList from './components/EventList';
import CheckIn from './components/CheckIn';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logo from './img/Logo1Comepi.png'; // Importar a logo

function App() {
  const [view, setView] = useState('eventList'); // Estado para alternar entre telas
  const [checkInHash, setCheckInHash] = useState(''); // Estado para armazenar o hash do check-in

  // Função para alternar para a tela de CheckIn com o hash
  const handleCheckIn = (hash) => {
    setCheckInHash(hash);
    setView('checkIn');
  };

  // Função para voltar à lista de eventos
  const handleBackToEventList = () => {
    setView('eventList');
  };

  return (
    <div className="app-container">
      <header className="app-header">
        <img src={logo} alt="Company Logo" className="logo" /> {/* Adiciona a logo */}
      </header>
      <main>
        {view === 'eventList' && <EventList onCheckIn={handleCheckIn} />}
        {view === 'checkIn' && <CheckIn hash={checkInHash} onBack={handleBackToEventList} />}
      </main>
    </div>
  );
}

export default App;